import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { CommonService } from 'src/app/_services/common.service';
import { isRegularExpressionLiteral } from 'typescript';

@Component({
  selector: 'app-seminar-data',
  templateUrl: './seminar-data.component.html',
  styleUrls: ['./seminar-data.component.css']
})
export class SeminarDataComponent implements OnInit {

  remainingDays: number = 0;
  seminarData: any;

  hasChange: boolean = false;

  @Input() set seminar(val: any) {
    this.seminarData = val;
    this.remainingDays = this.getDateDifference(new Date(this.seminarData?.preparationDate));
    this.addressFormGroup = new FormGroup({
      companyName: new FormControl(this.seminarData?.seminarAddress?.companyName, Validators.required),
      street: new FormControl(this.seminarData?.seminarAddress?.street, Validators.required),
      postcode: new FormControl(this.seminarData?.seminarAddress?.postcode, Validators.required),
      place: new FormControl(this.seminarData?.seminarAddress?.place, Validators.required),
      salutation: new FormControl(this.seminarData?.seminarAddress?.contactPerson?.salutation),
      firstName: new FormControl(this.seminarData?.seminarAddress?.contactPerson?.firstName, Validators.required),
      lastName: new FormControl(this.seminarData?.seminarAddress?.contactPerson?.lastName, Validators.required),
      email: new FormControl(this.seminarData?.seminarAddress?.contactPerson?.email, Validators.required),
      phone: new FormControl(this.seminarData?.seminarAddress?.contactPerson?.phone, Validators.required)
    });

    this.initialValueFormGroup = this.addressFormGroup.value;

    this.addressFormGroup.valueChanges.subscribe(value => {
      this.hasChange = Object.keys(this.initialValueFormGroup).some(key => this.addressFormGroup.value[key] != this.initialValueFormGroup[key])
    });


  }

  salutations: string[] = ['Herr', 'Frau', 'Divers'];

  addressFormGroup: FormGroup;
  initialValueFormGroup: any;

  isAddressDialogOpened: boolean = false;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {

  }

  

  public getDateDifference(timeStart: Date) {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    const currentDate = new Date();
    const utcCurrentDate = Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const utcTimeStart = Date.UTC(timeStart.getFullYear(), timeStart.getMonth(), timeStart.getDate())

  
    return Math.floor((utcTimeStart - utcCurrentDate) / MS_PER_DAY)
  }

  public async updateAddressData() {
    if (this.addressFormGroup.invalid) return
    this.commonService.sendToggleLoadingScreen();
    try {

      //checken, ob sich was geändert hat.
      let newSeminarAddress = await this.apiService.patchSeminarAddress(this.seminarData.seminarUuid, this.addressFormGroup.value).toPromise();
      this.seminarData.seminarAddress = newSeminarAddress;
      this.initialValueFormGroup = this.addressFormGroup.value;
      this.isAddressDialogOpened = false;
    } catch (error: any) {
      console.log(error);
    }
    this.commonService.sendToggleLoadingScreen();
  } 

}
