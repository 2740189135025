<div class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 loading-screen-wrapper" *ngIf="show">
    <div class="min-h-screen flex justify-center items-center bg-gray-200">

        <div class="boxes">
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="box">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
      
    </div>
</div>
