import {
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { ApiService } from 'src/app/_services/api.service';
import { CommonService } from 'src/app/_services/common.service';
import { CustomNotificationService } from 'src/app/_services/custom-notification.service';
import { NotificationType } from 'src/app/_shared/enums/notificationType';
import { PortalDisplayTypes } from 'src/app/_shared/enums/portalDisplayTypes';
import { restStatus } from 'src/app/_shared/enums/restStatus';

const createFormGroup = (dataItem: any) => {
  return new FormGroup({
    id: new FormControl(dataItem.id),
    salutation: new FormControl(dataItem.salutation),
    firstName: new FormControl(dataItem.firstName, Validators.required),
    lastName: new FormControl(dataItem.lastName, Validators.required),
    differingCertificateCompanyName: new FormControl(dataItem.differingCertificateCompanyName),
    email: new FormControl(dataItem.email),
    additiveField: new FormControl(dataItem.additiveField),
    birthplace: new FormControl(dataItem.birthplace),
    birthdate: new FormControl(dataItem.birthdate),
    status: new FormControl(dataItem.status),
  });
};

const matches = (el, selector) =>
  (el.matches || el.msMatchesSelector).call(el, selector);

@Component({
  selector: 'app-participants-grid',
  templateUrl: './participants-grid.component.html',
  styleUrls: ['./participants-grid.component.css'],
})
export class ParticipantsGridComponent implements OnInit {
  @ViewChild(GridComponent)
  private grid: GridComponent;

  @Input() participantsData: any[];
  @Input() seminarUuid: string;
  @Input() portalDisplayType: number;

  public formGroup: FormGroup;
  private editedRowIndex: number;
  private isNew = false;

  private docClickSubscription: any;

  isDeleteDialogOpened: boolean = false;
  selectedParticipant: any;

  salutations: string[] = ['Herr', 'Frau', 'Divers'];

  enumPortalDisplayTypes = PortalDisplayTypes;


  // Excel Import
  isExcelImportDialogOpened: boolean = false;
  uploadFilesFormGroup: FormGroup;
  selectedFiles: any[] = [];

  public myRestrictions: FileRestrictions = {
    allowedExtensions: [".xls", ".xlsx"],
  };



  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(
    private apiServive: ApiService,
    private renderer: Renderer2,
    private customNotificationService: CustomNotificationService,
    private commonService: CommonService
  ) {}

  public ngOnInit(): void {
    this.docClickSubscription = this.renderer.listen(
      'document',
      'click',
      this.onDocumentClick.bind(this)
    );

    this.uploadFilesFormGroup = new FormGroup({
      files: new FormControl(null, Validators.required)
    });
  }

  public ngOnDestroy(): void {
    this.docClickSubscription();
  }

  public addHandler(): void {
    this.closeEditor();

    this.formGroup = createFormGroup({
      id: null,
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      additiveField: '',
      birthdate: null,
      birthplace: null,
      status: true,
      differingCertificateCompanyName: null
    });
    this.isNew = true;

    this.grid.addRow(this.formGroup);
  }

  public saveRow() {
    if (this.formGroup && this.formGroup.valid) {
      this.saveCurrent();
    }
  }

  public async cellClickHandler({ isEdited, dataItem, rowIndex }) {
    if (
      isEdited ||
      this.isDeleteDialogOpened ||
      (this.formGroup && !this.formGroup.valid)
    ) {
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    await this.saveCurrent();

    this.formGroup = createFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    this.grid.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler(): void {
    this.closeEditor();
  }

  private closeEditor(): void {
    this.grid.closeRow(this.editedRowIndex);

    this.isNew = false;
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  private async onDocumentClick(e: any) {
    // if (
    //   this.formGroup &&
    //   this.formGroup.valid &&
    //   !matches(
    //     e.target,
    //     "#participantsGrid tbody *, #participantsGrid .k-grid-toolbar .k-button"
    //   )
    // ) {
    //   await this.saveCurrent();
    // }
  }

  private async saveCurrent(): Promise<void> {
    this.commonService.sendToggleLoadingScreen();

    if (this.isInEditingMode && this.formGroup) {
      if (this.isNew) {
        await this.apiServive
          .addParticipantToSeminar(this.formGroup.value, this.seminarUuid)
          .toPromise()
          .then((addedParticipant) => {
            this.participantsData.splice(0, 0, addedParticipant);
            this.customNotificationService.show(
              NotificationType.success,
              'Teilnehmer wurde erfolgreich hinzugefügt'
            );
          })
          .catch((error) => {
            this.customNotificationService.show(
              NotificationType.warning,
              error.error.message
            );
          });
      } else {
        //check if there already is such a row
        await this.apiServive
          .updateParticipantInSeminar(this.formGroup.value, this.seminarUuid)
          .toPromise()
          .then((addedParticipant) => {
            if (addedParticipant.type === restStatus.NotModified) {
              this.commonService.sendToggleLoadingScreen();
              return;
            }

            if (addedParticipant.type === restStatus.Duplicate) {
              this.participantsData = this.participantsData.filter(
                (element) => element.id != addedParticipant.participantData.id
              );
            }
            addedParticipant.participantData.birthdate =  (addedParticipant.participantData.birthdate) ? new Date(
              addedParticipant.participantData.birthdate
            ) : null;
            Object.assign(
              this.participantsData.find(
                ({ id }) => id === this.formGroup.value.id
              ),
              addedParticipant.participantData
            );
            this.customNotificationService.show(
              NotificationType.success,
              'Teilnehmerdaten wurden erfolgreich geändert.'
            );
          })
          .catch(async (error) => {
            this.customNotificationService.show(
              NotificationType.error,
              error.error.message
            );
          });
      }
    }

    this.closeEditor();
    this.commonService.sendToggleLoadingScreen();
  }

  public removeParticipant(event: any) {
    this.isDeleteDialogOpened = true;
    this.selectedParticipant = event.dataItem;
  }

  public async removeHandler() {
    this.commonService.sendToggleLoadingScreen();

    try {
      await this.apiServive
        .removeParticipantFromSeminar(
          this.seminarUuid,
          this.selectedParticipant.id
        )
        .toPromise();
      this.participantsData = this.participantsData.filter(
        (element) => element.id != this.selectedParticipant.id
      );
      this.customNotificationService.show(
        NotificationType.success,
        'Teilnehmer wurde erfolgreich entfernt.'
      );
      this.isDeleteDialogOpened = false;
    } catch (error: any) {
      this.customNotificationService.show(
        NotificationType.error,
        error.message
      );
    }
    this.commonService.sendToggleLoadingScreen();
  }

  public changeSalutation(value: any) {
    this.formGroup.patchValue({
      salutation: value,
    });
  }

  public setColors(context: RowClassArgs) {
    if (context.dataItem.status) {
      return { notAttended: false };
    }
    return { notAttended: true };
  }




  // excel import
  public async importParticipantsThroughExcel() {
    if (this.uploadFilesFormGroup.invalid ) {
      this.customNotificationService.show(NotificationType.warning, 'Bitte wählen Sie mind. eine valide Datei aus.');
      return;
    }
    this.commonService.sendToggleLoadingScreen();
    try {
      let formData = new FormData();
      formData.append('file', this.uploadFilesFormGroup.value.files[0]);
      let addedParticipants = await this.apiServive.participantsExcelImport(this.seminarUuid,formData).toPromise();

      for (let addedParticipant of addedParticipants) {
        this.participantsData.push(addedParticipant);
      }
      this.isExcelImportDialogOpened = false;

    } catch (error: any) {
      console.log(error);
      this.customNotificationService.show(NotificationType.error, 'Bei der Verarbeitung der Daten ist ein Fehler aufgetreten.');
    }
    this.commonService.sendToggleLoadingScreen();

  }



  public checkPortalType(portalType: PortalDisplayTypes[]) {
    return portalType.includes(this.portalDisplayType)
  }

}
