import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private refreshingInProgress: boolean;
  private accessTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);


  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var user = this.localStorageService.getCurrentUser();
    if (!user) return next.handle(req);
    const accessToken = user.accesstoken;

    return next.handle(this.addAuthorizationHeader(req, accessToken)).pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            const refreshToken = user.refreshtoken;
            //if (refreshToken && accessToken) return this.refreshToken(req, next);
            return this.logoutAndRedirect(err);
          }
          if (err instanceof HttpErrorResponse && err.status === 403) {
            return this.logoutAndRedirect(err);
          }
          return throwError(err);
        })
    );
  }
  private logoutAndRedirect(err): Observable<HttpEvent<any>> {
    this.authService.logout();
    

    return throwError(err);
  }

  private addAuthorizationHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (token) return request.clone({setHeaders: {Authorization: `Bearer ${token}`}})
    return request
  }

  private refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.refreshingInProgress) {
      this.refreshingInProgress = true;
      this.accessTokenSubject.next(null);
      return this.authService.refreshtoken().pipe(
        switchMap((res) => {
          this.refreshingInProgress = false;
          this.accessTokenSubject.next(res.accesstoken);

          return next.handle(this.addAuthorizationHeader(request, res.accesstoken));
        })
      );
    } else {
      return this.accessTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthorizationHeader(request, token));
        }
      ))
    }

  }
}