<div class="max-w-7xl mx-auto px-8 mt-4">
  <div
    class="bg-blue-500 shadow-sm px-4 py-2 flex flex-wrap justify-between items-center"
  >
    <div>
      <span class="text-white">Seminarinformationen</span>
    </div>

    <div>
      <span class="text-white text-sm">
        Sie können dieses Seminar noch {{ remainingDays }} Tage bearbeiten.
      </span>
    </div>

    <div (click)="isAddressDialogOpened = true" class="cursor-pointer">
      <button
        kendoButton
        size="large"
        themeColor="secondary"
        class="text-blue-700"
        icon="edit"
      >
        Seminardaten bearbeiten
      </button>
    </div>
  </div>

  <div class="bg-white px-4 pt-4">
    <div class="mt-2 rounded bg-white">
      <div class="mb-2">
        <p class="text-2xl text-gray-700">Seminardaten</p>
        <p class="text-md text-gray-600">
          Überblick über die wichtigsten Daten Ihres Seminars.
        </p>
      </div>

      <div>
        <ul
          role="list"
          class="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3"
        >
          <li class="col-span-1 flex rounded-sm">
            <div
              class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-600 text-sm font-medium rounded-l-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-sm truncate"
            >
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class= "text-gray-900 font-medium hover:text-gray-600"
                  >Seminar</span
                >
                <p class="text-gray-500">{{ seminarData?.course?.name }}</p>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex rounded-sm">
            <div
              class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-600 text-sm font-medium rounded-l-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-sm truncate"
            >
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class="text-gray-900 font-medium hover:text-gray-600"
                  >Durchführung</span
                >
                <p class="text-gray-500">{{ seminarData?.execution?.name }}</p>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex rounded-sm">
            <div
              class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-600 text-sm font-medium rounded-l-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-sm truncate"
            >
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class="text-gray-900 font-medium hover:text-gray-600"
                  >Datum / Uhrzeit</span
                >
                <p class="text-gray-500">
                  {{ seminarData?.timeStart | date: "dd.MM.yyyy HH:mm" }} -
                  {{ seminarData?.timeEnd | date: "dd.MM.yyyy HH:mm" }}
                </p>
              </div>
            </div>
          </li>

          <li class="col-span-2 flex rounded-sm">
            <div
              class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-600 text-sm font-medium rounded-l-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-sm truncate"
            >
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class="text-gray-900 font-medium hover:text-gray-600"
                  >Seminarort</span
                >
                <p class="text-gray-500">
                  {{ seminarData?.seminarAddress?.companyName }}
                  <br />
                  {{ seminarData?.seminarAddress?.street }}
                  <br />
                  {{ seminarData?.seminarAddress?.postcode }},
                  {{ seminarData?.seminarAddress?.place }}
                </p>
              </div>
            </div>
          </li>

          <li class="col-span-1 flex rounded-sm">
            <div
              class="flex-shrink-0 flex items-center justify-center w-16 bg-blue-200 text-blue-600 text-sm font-medium rounded-l-sm"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                />
              </svg>
            </div>
            <div
              class="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-sm truncate"
            >
              <div class="flex-1 px-4 py-2 text-sm truncate">
                <span class="text-gray-900 font-medium hover:text-gray-600"
                  >Ansprechpartner vor Ort</span
                >
                <p class="text-gray-500">
                  {{ seminarData?.seminarAddress?.contactPerson?.salutation }}
                  {{ seminarData?.seminarAddress?.contactPerson?.firstName }}
                  {{ seminarData?.seminarAddress?.contactPerson?.lastName }}
                  <br />
                  {{ seminarData?.seminarAddress?.contactPerson?.email }}
                  <br />
                  {{ seminarData?.seminarAddress?.contactPerson?.phone }}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <kendo-dialog
    title="Seminardaten anpassen"
    *ngIf="isAddressDialogOpened"
    (close)="isAddressDialogOpened = false"
    [minWidth]="250"
    [width]="750"
  >
    <div class="" style="max-height: 85vh">
      <form
        [formGroup]="addressFormGroup"
        class="grid md:grid-cols-2 gap-8 overflow-y-visible"
      >
        <div class="">
          <h2 class="text-2xl">Seminarort</h2>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Firma</label>
            <kendo-textbox placeholder="Firma" formControlName="companyName">
            </kendo-textbox>
          </div>

          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Straße</label>
            <kendo-textbox placeholder="Straße" formControlName="street">
            </kendo-textbox>
          </div>

          <div class="my-4">
            <label class="text-base font-medium text-gray-900"
              >Postleitzahl</label
            >
            <kendo-textbox
              placeholder="Postleitzahl"
              formControlName="postcode"
            >
            </kendo-textbox>
          </div>

          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Ort</label>
            <kendo-textbox placeholder="Ort" formControlName="place">
            </kendo-textbox>
          </div>
        </div>

        <div class="">
          <h2 class="text-2xl">Ansprechpartner vor Ort</h2>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Anrede</label>
            <kendo-dropdownlist
              [data]="salutations"
              formControlName="salutation"
            ></kendo-dropdownlist>
          </div>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Vorname</label>
            <kendo-textbox placeholder="Vorname" formControlName="firstName">
            </kendo-textbox>
          </div>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900">Nachname</label>
            <kendo-textbox placeholder="Nachname" formControlName="lastName">
            </kendo-textbox>
          </div>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900">E-Mail</label>
            <kendo-textbox placeholder="E-Mail" formControlName="email">
            </kendo-textbox>
          </div>
          <div class="my-4">
            <label class="text-base font-medium text-gray-900"
              >Telefonnummer</label
            >
            <kendo-textbox placeholder="Telefonnummer" formControlName="phone">
            </kendo-textbox>
          </div>
        </div>
      </form>

      <kendo-dialog-actions>
        <button
          kendoButton
          icon="close"
          size="large"
          themeColor="error"
          (click)="isAddressDialogOpened = false"
        >
          Abbrechen
        </button>
        <button
          kendoButton
          size="large"
          (click)="updateAddressData()"
          themeColor="success"
          icon="save"
          [disabled]="!hasChange"
        >
          Seminardaten ändern
        </button>
      </kendo-dialog-actions>
    </div>
  </kendo-dialog>
</div>
