import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { EvaluateComponent } from './_components/evaluate/evaluate.component';
import { InstructionComponent } from './_pages/instruction/instruction.component';
import { LoginComponent } from './_pages/login/login.component';
import { SeminarComponent } from './_pages/seminar/seminar.component';
import { SeminarsOverviewComponent } from './_pages/seminars-overview/seminars-overview.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'dashboard', pathMatch: 'full', redirectTo: 'seminars' },
  {
    path: 'seminars',
    pathMatch: 'full',
    component: SeminarsOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seminars/:seminarUuid',
    pathMatch: 'full',
    component: SeminarComponent,
    canActivate: [AuthGuard],
  },
  { path: 'hilfe', pathMatch: 'full', component: InstructionComponent },
  { path: 'bewerten', pathMatch: 'full', component: EvaluateComponent },
  { path: '**', redirectTo: 'seminars' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
