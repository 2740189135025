<app-top-navbar></app-top-navbar>

<app-seminar-info></app-seminar-info>

<div class="max-w-7xl mx-auto px-8 mt-8">
  <div class="bg-blue-100 p-4 rounded border border-blue-500 text-blue-500">
    <p>
      Willkommen in unserem neuen ITC Connect Portal. Hier haben Sie die
      Möglichkeit alle Ihre Schulungen und Seminare zu verwalten. Gerne können
      Sie unser Portal auf eigene Faust erkunden oder Sie werfen einen Blick in
      unsere Anleitung.
    </p>
    <br />
    <div class="mt-1 mb-2">
      <a href="/hilfe" class="p-3 bg-blue-600 text-white hover:bg-blue-500">
        ITC Connect Portal - Anleitung
      </a>
    </div>
  </div>
</div>

<div class="max-w-7xl mx-auto px-8 mt-8">
  <div
    class="bg-blue-500 shadow-sm px-4 py-2 flex flex-wrap justify-between items-center"
  >
    <div>
      <span class="text-white">Seminarübersicht</span>
    </div>
  </div>
  <div class="bg-white px-3 pb-8 max-w-7xl mx-auto py-3">
    <div>
      <p class="text-xl text-gray-700">Seminarliste</p>
      <p class="text-sm text-gray-600 pb-4">
        Bitte wählen Sie ein Seminar aus.
      </p>
    </div>
    <kendo-grid
      #seminarData
      [kendoGridBinding]="seminars"
      (cellClick)="getSingleSeminar(seminarData, $event)"
    >
      <kendo-grid-column field="course.name" title="Seminar">
      </kendo-grid-column>
      <kendo-grid-column field="companyAddress.companyName" title="Firma">
      </kendo-grid-column>

      <kendo-grid-column
        field="participants.length"
        title="Anzahl gemeldeter Teilnehmer"
      >
      </kendo-grid-column>
      <kendo-grid-column title="Datum / Uhrzeit">
        <ng-template kendoGridCellTemplate let-dataItem>
          <p>{{ dataItem.timeStart | date: "dd.MM.yyyy HH:mm" }}</p>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="seminarStatus.name" title="Status">
      </kendo-grid-column>
    </kendo-grid>
    <div class="mt-3">
      <a routerLink="/hilfe" class="text-blue-500 text-right"
        >Anleitung und Hilfe</a
      >
    </div>
  </div>
</div>

<app-footer></app-footer>
