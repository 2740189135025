import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.css']
})
export class TopNavbarComponent implements OnInit {

  contactPerson: any;

  constructor(
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.contactPerson = this.localStorageService.getCurrentUser();
  }

  public logout() {
    this.authenticationService.logout();
  }

}
