<div class="h-screen"></div>
<footer class="bg-white fixed bottom-0 w-full">
  <div class="max-w-7xl mx-auto py-2 px-4 overflow-hidden sm:px-6 lg:px-8">
    <nav
      class="-mx-5 -my-2 flex flex-wrap justify-center text-sm"
      aria-label="Footer"
    >
      <div class="px-5 py-2">
        <a href="https://itc-graf.de" class="text-gray-500 hover:text-gray-900">
          ITC Graf GmbH
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/arbeitsschutz"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Arbeitsschutz
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/brandschutz"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Brandschutz
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/kategorien-schulungen"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Seminare
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/thema-des-monats"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Thema des Monats
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/impressum"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Impressum
        </a>
      </div>

      <div class="px-5 py-2">
        <a
          href="https://itc-graf.de/datenschutz"
          target="_blank"
          class="text-gray-500 hover:text-gray-900"
        >
          Datenschutzerklärung
        </a>
      </div>
    </nav>
  </div>
</footer>
