import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { error } from 'protractor';
import { first } from 'rxjs/operators';
import { UserAuth } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { CommonService } from 'src/app/_services/common.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  submitted: boolean = false;
  message = {
    status: false,
    text: ''
  }
  loginForm: FormGroup;
  returnUrl: string = '';
  seminarUuid: string = '';
  tenant: string = '';
  email: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService
  ) {
   }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    this.seminarUuid = this.route.snapshot.queryParams['seminarUuid'];
    this.tenant = this.route.snapshot.queryParams['tenant'];
    this.email = this.route.snapshot.queryParams['email'];
    this.loginForm = new FormGroup({
      email: new FormControl(this.email, Validators.required),
      password: new FormControl(null, Validators.required)
    });
  }

  public async onSubmit(event?: Event): Promise<void> {
    this.submitted = true;
    this.message.status = false;
    if (this.loginForm.invalid) return
    this.commonService.sendToggleLoadingScreen();

    await this.authenticationService.login(this.loginForm.value.email, this.loginForm.value.password, this.seminarUuid, this.tenant).toPromise()
      .then((user: UserAuth) => {
        this.router.navigate([this.returnUrl]);
      })
      .catch(error => {
          this.message.text = error.error.message;
          this.message.status = true;
      });
    
      this.commonService.sendToggleLoadingScreen();

  }

}
