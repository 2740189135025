import { UserAuth } from '../_models/user';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getCurrentUser() {
    const user: UserAuth = JSON.parse(localStorage.getItem('currentUser')!);
    return user
  }
}