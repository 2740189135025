import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationTypes } from '../_shared/enums/navigationTypes';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private router: Router
  ) { }

  public navigate(type: NavigationTypes, id?: number) {
    switch (type) {
      case NavigationTypes.login:
        this.router.navigate(['']);
        break;
      
      case NavigationTypes.dashboard:
        this.router.navigate(['dashboard']);
        break;
      
      case NavigationTypes.seminars:
        this.router.navigate(['seminars'])
        break;
      
      case NavigationTypes.seminar:
        this.router.navigate([`seminars/${ id }`])
      

    }
  }
}