<div>
  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-col flex-grow pt-5 bg-blue-600 overflow-y-auto">
      <div class="flex items-center flex-shrink-0 px-4">
        <img
          class="h-8 w-auto"
          src="../../../../assets/images/logo_white.svg"
          alt="ITC Connect"
        />
      </div>
      <div class="mt-5 flex-1 flex flex-col">
        <div class="px-4 pt-5 pb-2 text-white text-lg font-medium">
          <p>Anleitungen</p>
        </div>
        <nav class="flex-1 px-2 pb-4 space-y-1">
          <a
            href="hilfe#einstieg"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            Einstieg
          </a>
          <a
            href="hilfe#anmeldung"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
              />
            </svg>
            Anmeldung
          </a>
          <a
            href="hilfe#eintragen"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
              />
            </svg>
            Teilnehmer eintragen
          </a>
          <a
            href="hilfe#teilnehmerBearbeiten"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
              />
            </svg>
            Teilnehmer bearbeiten
          </a>
          <a
            href="hilfe#teilnehmerEntfernen"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 7a4 4 0 11-8 0 4 4 0 018 0zM9 14a6 6 0 00-6 6v1h12v-1a6 6 0 00-6-6zM21 12h-6"
              />
            </svg>
            Teilnehmer entfernen
          </a>
          <div class="px-4 pt-5 pb-2 text-white text-lg font-medium">
            <p>Dokumente</p>
          </div>
          <a
            href="#"
            class="text-blue-50 hover:bg-blue-700 group flex items-center px-2 py-2 rounded-md"
          >
            <svg
              class="mr-3 flex-shrink-0 h-6 w-6 text-indigo-300"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Verfügbare Dokumente (Bald verfügbar)
          </a>
        </nav>
      </div>
    </div>
  </div>
  <div class="md:pl-64 flex flex-col flex-1">
    <div class="">
      <div class="shadow-lg bg-blue-600">
        <div class="max-w-7xl mx-auto px-8 py-3">
          <div class="flex flex-wrap justify-center items-center">
            <div class="hidden sm:block">
              <span
                class="text-sm text-blue-800 bg-blue-300 py-1 px-16 rounded-sm"
              >
                Anleitung und Dokumenten</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <main class="bg-white min-h-screen px-8" id="einstieg">
      <div class="max-w-7xl mx-auto">
        <div class="mt-10">
          <p class="text-slate-700">ITC Connect</p>
          <h1 class="text-3xl mt-1 font-medium text-slate-800">
            ITC Connect Anleitungen und Dokumente
          </h1>
          <p class="mt-2 text-xl text-slate-700">
            Hier finden Sie Anleitungen und Dokumente rund um ITC Connect
          </p>
          <p class="text-slate-700 mt-2 leading-relaxed max-w-5xl">
            ITC Connect bietet Ihnen die Möglichkeit Seminardaten flexibel zu
            verwalten und zu ändern - sogar bis wenige Tage vor Seminarstart.
            Verwalten Sie Ihre Seminare, Teilnehmer und Seminardaten wie
            Seminarort oder Ansprechpartner.
          </p>
          <h2 class="text-xl font-medium mt-8 text-slate-800">
            Beliebte Themen
          </h2>
          <div class="mt-4 grid grid-cols-1 lg:grid-cols-4 gap-4">
            <a href="hilfe#eintragen">
              <div class="bg-slate-50 border pb-6 pt-4 pl-3 pr-3 rounded">
                <img
                  class="h-48 mx-auto"
                  src="../../../assets/images/undraw_meet_the_team_re_4h08.svg"
                  alt=""
                />
                <p class="text-xl mt-3 text-slate-800 font-medium">
                  Teilnehmer eintragen
                </p>
                <p class="text-slate-700 mt-1">
                  Tragen Sie Teilnehmer zu Ihren Seminaren ein.
                </p>
              </div>
            </a>
            <a href="hilfe#bearbeiten">
              <div class="bg-slate-50 border pb-6 pt-4 pl-3 pr-3 rounded">
                <img
                  class="h-48 mx-auto"
                  src="../../../assets/images/undraw_text_field_htlv.svg"
                  alt=""
                />
                <p class="text-xl mt-3 text-slate-800 font-medium">
                  Teilnehmer bearbeiten
                </p>
                <p class="text-slate-700 mt-1">
                  Bearbeiten Sie Teilnehmer zu Ihren Seminaren.
                </p>
              </div>
            </a>
            <a href="https://itc-graf.de/kontakt">
              <div class="bg-slate-50 border pb-6 pt-4 pl-3 pr-3 rounded">
                <img
                  class="h-48 mx-auto"
                  src="../../../assets/images/undraw_forgot_password_re_hxwm.svg"
                  alt=""
                />
                <p class="text-xl mt-3 text-slate-800 font-medium">
                  Passwort vergessen
                </p>
                <p class="text-slate-700 mt-1">
                  Bitte wenden Sie sich beim Verlust an die ITC Graf GmbH.
                </p>
              </div>
            </a>
            <a href="https://itc-graf.de/kontakt">
              <div class="bg-slate-50 border pb-6 pt-4 pl-3 pr-3 rounded">
                <img
                  class="h-48 mx-auto"
                  src="../../../assets/images/undraw_team_re_0bfe.svg"
                  alt=""
                />
                <p class="text-xl mt-3 text-slate-800 font-medium">
                  Support und Kontakt
                </p>
                <p class="text-slate-700 mt-1">
                  Gerne stehen wir Ihnen bei Fragen zur Verfügung.
                </p>
              </div>
            </a>
          </div>
          <h2 class="text-xl font-medium mt-8 text-slate-800">
            Benötigte Informationen zu Teilnehmern
          </h2>
          <p class="text-slate-700 mt-2 leading-relaxed max-w-5xl">
            Um Teilnehmer in ITC Connect zu hinterlegegen werden pro Teilnehmer
            folgende Informationen benötigt:
          </p>
          <div class="mt-2">
            <kendo-grid [data]="gridData">
              <ng-template kendoGridToolbarTemplate>
                <button
                  kendoButton
                  size="large"
                  kendoButton
                  size="large"
                  themeColor="secondary"
                  class="text-blue-700"
                  kendoGridAddCommand
                >
                  <kendo-icon name="plus"></kendo-icon>

                  Teilnehmer hinzufügen
                </button>
              </ng-template>
              <kendo-grid-column field="anrede" title="Anrede">
              </kendo-grid-column>
              <kendo-grid-column field="vorname" title="Vorname">
              </kendo-grid-column>
              <kendo-grid-column field="nachname" title="Nachname">
              </kendo-grid-column>
              <kendo-grid-column field="zusatzfeld" title="Zusatzfeld">
              </kendo-grid-column>
              <kendo-grid-column field="geburtsdatum" title="Geburtsdatum">
              </kendo-grid-column>
            </kendo-grid>
          </div>
          <p class="text-slate-700 mt-2 leading-relaxed max-w-5xl">
            Bei den Feldern: Vorname, Nachname handelt es sich um Pflichtfelder,
            diese müssen gepflegt sein, damit der Eintrag für den Teilnehmer
            gespeichert werden kann.
          </p>
          <h2 class="text-xl font-medium mt-8 text-slate-800">Schnellstart</h2>
          <p class="text-slate-700 mt-2 leading-relaxed max-w-5xl">
            Sehen Sie sich unsere Video-Anleitung an und beginnen Sie direkt mit
            dem Eintragen Ihrer Teilnehmer.
          </p>
          <button
            #anchor
            kendoButton
            themeColor="primary"
            class="mt-2"
            (click)="showVideo = !showVideo"
          >
            <kendo-icon name="play"></kendo-icon>

            Video Anleitung anzeigen
          </button>

          <kendo-popup [anchor]="anchor" *ngIf="!showVideo">
            <div class="p-4 bg-gray-50">
              <h2 class="text-xl font-medium text-slate-800">
                Video Anleitungen
              </h2>
              <p class="text-slate-700 mt-2 leading-relaxed max-w-5xl">
                In dieser Anleitung zeigen wir Ihnen, wie Sie Ihre Teilnehmer
                eintragen und bearbeiten können.
              </p>
              <div class="">BALD VERFÜGBAR</div>
            </div>
          </kendo-popup>
        </div>

        <div *ngFor="let instruction of instruction" class="">
          <div class="my-10 mb-32" id="{{ instruction.link }}">
            <!-- Section -->
            <div class="">
              <p class="text-slate-700">
                {{ instruction.section }}
              </p>
              <h1 class="text-3xl mt-1 font-medium text-slate-800">
                {{ instruction.heading }}
              </h1>
              <p class="mt-2 text-xl text-slate-700">
                {{ instruction.intro }}
              </p>
            </div>

            <div class="mt-10" *ngIf="instruction.steps">
              <div
                *ngFor="let step of instruction.steps"
                class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-20 items-baseline"
              >
                <div class="lg:sticky lg:top-14">
                  <p class="text-xl text-slate-800 font-semibold">
                    {{ step.point }}
                  </p>
                  <p class="text-slate-700 mt-2 leading-relaxed">
                    {{ step.content }}
                  </p>
                </div>
                <div class="">
                  <img
                    class="hover:shadow-lg hover:rounded w-5/6 object-contain mx-auto"
                    src="{{ step.image }}"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
