import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './_pages/login/login.component';
import { LoginFormComponent } from './_components/login-form/login-form.component';
import { DangerAlertComponent } from './_components/elements/danger-alert/danger-alert.component';

import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule, HttpClient} from "@angular/common/http";
import { GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { IntlModule } from '@progress/kendo-angular-intl';
import { EditService } from './_services/edit.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { RouterModule } from '@angular/router';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { InputsModule } from '@progress/kendo-angular-inputs';

import { NotificationModule } from '@progress/kendo-angular-notification';
import { CustomNotificationService } from './_services/custom-notification.service';
import { NotificationComponent } from './_components/elements/notification/notification.component';
import { TokenInterceptor } from './_services/token-interceptor.service';
import { ParticipantsGridComponent } from './_components/elements/participants-grid/participants-grid.component';
import { SeminarDataComponent } from './_components/elements/seminar-data/seminar-data.component';
import { EvaluateComponent } from './_components/evaluate/evaluate.component';
import { SeminarsOverviewComponent } from './_pages/seminars-overview/seminars-overview.component';
import { SeminarComponent } from './_pages/seminar/seminar.component';
import { TopNavbarComponent } from './_components/elements/top-navbar/top-navbar.component';
import { SeminarInfoComponent } from './_components/elements/seminar-info/seminar-info.component';
import { FooterComponent } from './_components/footer/footer.component';
import { LoadingScreenComponent } from './_components/loading-screen/loading-screen.component';
import { InstructionComponent } from './_pages/instruction/instruction.component';
import { IconsModule } from '@progress/kendo-angular-icons';






@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginFormComponent,
    DangerAlertComponent,
    NotificationComponent,
    ParticipantsGridComponent,
    SeminarDataComponent,
    EvaluateComponent,
    SeminarsOverviewComponent,
    SeminarComponent,
    TopNavbarComponent,
    SeminarInfoComponent,
    FooterComponent,
    LoadingScreenComponent,
    InstructionComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DialogsModule,
    BrowserAnimationsModule,
    GridModule,
    ButtonsModule,
    ListViewModule,
    RouterModule,
    IntlModule,
    HttpClientModule,
    HttpClientJsonpModule,
    DropDownsModule,
    DateInputsModule,
    UploadsModule,
    InputsModule,
    NotificationModule,
    IconsModule,
  ],
  providers: [
    CustomNotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
