import { environment } from '../../environments/environment';
import { UserAuth } from '../_models/user';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

interface refreshtoken {
  accesstoken: string,
  refreshtoken: string
}


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<UserAuth>;
  public currentUser: Observable<UserAuth>;
  private readonly apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.apiUrl = environment.hostUrl;
    this.currentUserSubject = new BehaviorSubject<UserAuth>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserAuth {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string, seminarUuid: string, tenant: string): Observable<any> {
    const headers = new HttpHeaders({'Content-Type':'application/json'});

    return this.httpClient
        .post<any>(`${this.apiUrl}/api/v1/customerportal/authentication`, { email: email, password: password, seminarUuid: seminarUuid, tenant: tenant }, { headers: headers })
      .pipe(map((user: UserAuth) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  } 

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.router.navigate(['/']);
    //this.currentUserSubject.next(null);
  }

  refreshtoken(): Observable<refreshtoken> {
    const user: UserAuth = JSON.parse(localStorage.getItem('currentUser')!);
    const currentRefreshtoken = user.refreshtoken;

    return this.httpClient.post<refreshtoken>(`${this.apiUrl}/api/v1/customerportal/authentication/refreshtoken`, { refreshtoken: currentRefreshtoken})
      .pipe(tap((response: refreshtoken) => {
        user.accesstoken = response.accesstoken;
        user.refreshtoken = response.refreshtoken;
        localStorage.setItem('currentUser', JSON.stringify(user));

      }))
  }
}