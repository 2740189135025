import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, pipe } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly apiUrl: string;

  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    this.apiUrl = environment.hostUrl
   }

   private getHeader() {
     let accessToken = this.localStorageService.getCurrentUser().accesstoken;
    const headers = ({
      'Authorization': `Bearer ${accessToken}`
    });
    return headers
  }

  public getSeminarsByContactPerson (contactPersonId: number): Observable<any> {
    return this.httpClient
      .get<any>(`${ this.apiUrl }/api/v1/customerportal/contactperson/${ contactPersonId }/seminars`, { headers: this.getHeader() })
      .pipe(shareReplay());
  }

  public getSingleSeminar(seminarUuid: string): Observable<any> {
    return this.httpClient
    .get<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${ seminarUuid }`, { headers: this.getHeader() })
    .pipe(shareReplay());
  }

  public addParticipantToSeminar(participantData: any, seminarUuid: string) {
    return this.httpClient
    .put<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${ seminarUuid }/participants`, participantData, { headers: this.getHeader() })
    .pipe(shareReplay());
  }

  public updateParticipantInSeminar(participantData: any, seminarUuid: string): Observable<any> {
    return this.httpClient
      .patch<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${ seminarUuid }/participants/${ participantData.id }`, participantData, { headers: this.getHeader() })
      .pipe(shareReplay());
  }

  public removeParticipantFromSeminar(seminarUuid: string, participantId: number): Observable<any> {
    return this.httpClient
      .delete<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${ seminarUuid }/participants/${ participantId }`, { headers: this.getHeader() })
      .pipe(shareReplay());
  }

  public patchSeminarAddress(seminarUuid: string, data: object): Observable<any> {
    return this.httpClient
      .patch<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${ seminarUuid }/seminarAddress`, data, { headers: this.getHeader() })
      .pipe(shareReplay());
  }

  public participantsExcelImport(seminarUuid: string, data: object): Observable<any> {
    return this.httpClient
      .post<any>(`${ this.apiUrl }/api/v1/customerportal/seminars/${seminarUuid}/participants/excel`, data, { headers: this.getHeader() })
      .pipe(shareReplay());
  }





}