<div
  class=""
  style="
    background: linear-gradient(
        rgba(43, 43, 43, 0) -10px,
        rgba(29, 29, 29, 0.8) 60%
      ),
      url(https://itc-graf.de/uploads/Uploads-Startseite/ITC-Graf-GmbH.webp);
    background-size: cover;
    background-position: center;
  "
>
  <div class="h-screen flex items-center">
    <div class="max-w-7xl mx-auto py-16 px-4">
      <div class="relative bg-white shadow-xl">
        <h2 class="sr-only">Teilnehmer verwaltung</h2>

        <div class="grid grid-cols-1 lg:grid-cols-3">
          <div
            class="
              relative
              overflow-hidden
              py-10
              px-6
              bg-blue-800
              sm:px-10
              xl:p-12
            "
          >
            <div
              class="absolute inset-0 pointer-events-none sm:hidden"
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="343"
                height="388"
                viewBox="0 0 343 388"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                  fill="url(#linear1)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear1"
                    x1="254.553"
                    y1="107.554"
                    x2="961.66"
                    y2="814.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="
                hidden
                absolute
                top-0
                right-0
                bottom-0
                w-1/2
                pointer-events-none
                sm:block
                lg:hidden
              "
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="359"
                height="339"
                viewBox="0 0 359 339"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                  fill="url(#linear2)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear2"
                    x1="192.553"
                    y1="28.553"
                    x2="899.66"
                    y2="735.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="
                hidden
                absolute
                top-0
                right-0
                bottom-0
                w-1/2
                pointer-events-none
                lg:block
              "
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="160"
                height="678"
                viewBox="0 0 160 678"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                  fill="url(#linear3)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear3"
                    x1="192.553"
                    y1="325.553"
                    x2="899.66"
                    y2="1032.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff"></stop>
                    <stop offset="1" stop-color="#fff" stop-opacity="0"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h3 class="text-lg font-medium text-white">
              Ihr Seminar steht kurz bevor
            </h3>
            <p class="mt-6 text-base text-blue-50 max-w-3xl">
              Um die bestmögliche Vorbereitung für Ihr Seminar zu gewährleisten,
              können Sie direkt online Ihre Teilnehmer eintragen und verwalten.
              <br /><br />
              Bei Fragen stehen wir Ihnen jederzeit zur Verfügung.
            </p>
            <dl class="mt-8 space-y-6">
              <dt><span class="sr-only">Telefon</span></dt>
              <dd class="flex text-base text-blue-50">
                <svg
                  class="flex-shrink-0 w-6 h-6 text-blue-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <span class="ml-3"
                  ><a href="tel:0732127830">07321 - 27 83 0</a></span
                >
              </dd>
              <dt><span class="sr-only">Email</span></dt>
              <dd class="flex text-base text-blue-50">
                <svg
                  class="flex-shrink-0 w-6 h-6 text-blue-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span class="ml-3"
                  ><a href="mailto:connect@itc-graf.de" target="_blank"
                    >connect@itc-graf.de</a
                  ></span
                >
              </dd>
              <dt><span class="sr-only">Impressum</span></dt>
              <dd class="flex text-base text-blue-50">
                <svg
                  class="flex-shrink-0 w-6 h-6 text-blue-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-3"
                  ><a href="https://itc-graf.de/impressum">Impressum</a></span
                >
              </dd>
              <dt><span class="sr-only">Datenschutz</span></dt>
              <dd class="flex text-base text-blue-50">
                <svg
                  class="flex-shrink-0 w-6 h-6 text-blue-200"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span class="ml-3"
                  ><a href="https://itc-graf.de/datenschutz" target="_blank"
                    >Datenschutzerklärung</a
                  ></span
                >
              </dd>
            </dl>
          </div>

          <!-- Login-Form -->
          <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-16">
            <div
              class="flex flex-wrap items-center content-center text-center"
              style=""
            >
              <div class="mt-8 w-full">
                <div class="mb-4">
                  <img
                    class="h-16 mx-auto"
                    src="../../../assets/images/logo.svg"
                    alt=""
                  />
                </div>
                <h3 class="text-xl text-gray-600">
                  Melden Sie sich am ITC-Connect Portal an
                </h3>
                <div class="mt-6">
                  <form [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
                    <input
                      id="email"
                      name="email"
                      formControlName="email"
                      type="text"
                      autocomplete="text"
                      class="
                        px-2
                        py-2
                        border border-gray-300
                        rounded
                        w-96
                        shadow-sm
                      "
                      placeholder="Ihre E-Mail"
                    />
                    <br />
                    <input
                      id="password"
                      name="password"
                      type="password"
                      formControlName="password"
                      autocomplete="current-password"
                      class="
                        px-2
                        py-2
                        border border-gray-300
                        rounded
                        w-96
                        shadow-sm
                        mt-4
                      "
                      placeholder="Passwort"
                    />
                    <!-- This example requires Tailwind CSS v2.0+ -->
                    <app-danger-alert
                      *ngIf="message.status"
                      [text]="message.text"
                    ></app-danger-alert>

                    <p class="max-w-sm mx-auto mt-4 text-gray-500 text-sm">
                      Ihre Zugangsdaten erhalten Sie per E-Mail. Sollten Sie
                      keine Daten erhalten haben kontaktieren Sie uns.
                    </p>
                    <div class="mt-4">
                      <button
                        type="submit"
                        class="
                          inline-flex
                          items-center
                          px-4
                          py-2
                          border border-transparent
                          text-sm
                          font-medium
                          rounded-md
                          text-white
                          bg-blue-600
                          hover:bg-blue-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-blue-500
                        "
                      >
                        Anmelden
                        <svg
                          class="-mr-1 ml-2 h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
