import { Injectable } from '@angular/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Observable, Subject } from 'rxjs';
import { Notification } from '../_models/notification';
import { NotificationType } from '../_shared/enums/notificationType';

@Injectable({
  providedIn: 'root'
})
export class CustomNotificationService {

  private subject = new Subject<any>();


  constructor(
    private notificationService: NotificationService
  ) { }

  getObservable(): Observable<any> {
    return this.subject.asObservable();
  }


  show(notificationType: NotificationType, message: string) {
    this.subject.next({ notificationType: notificationType, message: message });
  }
}
