<div class="max-w-7xl mx-auto px-8">
  <div class="px-4 pt-10 pb-4 bg-white">
    <div class="mb-2">
      <p class="text-2xl text-gray-700">Teilnehmer</p>
      <p class="text-md text-gray-600">Sie können diese Liste bearbeiten.</p>
    </div>
    <kendo-grid
      [kendoGridBinding]="participantsData"
      [resizable]="true"
      id="participantsGrid"
      (cellClick)="cellClickHandler($event)"
      (add)="addHandler()"
      (remove)="removeParticipant($event)"
    >
      <ng-template kendoGridToolbarTemplate>
        <button
          kendoButton
          size="large"
          kendoButton
          size="large"
          themeColor="secondary"
          class="text-blue-700"
          kendoGridAddCommand
          *ngIf="!formGroup"
        >
          <kendo-icon name="plus"></kendo-icon>
          Teilnehmer hinzufügen
        </button>

        <button
          kendoButton
          size="large"
          kendoButton
          size="large"
          themeColor="secondary"
          class="text-blue-700"
          (click)="isExcelImportDialogOpened = true"
          *ngIf="!formGroup"
        >
          <kendo-icon name="plus"></kendo-icon>
          Excel-Import
        </button>

        <div *ngIf="formGroup">
          <button
            kendoButton
            themeColor="success"
            icon="save"
            size="large"
            class="mr-2"
            [disabled]="!formGroup.valid"
            (click)="saveRow()"
          >
            Speichern
          </button>
          <button
            kendoButton
            themeColor="error"
            icon="close"
            size="large"
            (click)="cancelHandler()"
          >
            Abbrechen
          </button>
        </div>
      </ng-template>

      <kendo-grid-column
        tabindex="1"
        field="salutation"
        title="Anrede (optional)"
      >
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <kendo-dropdownlist
            [data]="salutations"
            [(ngModel)]="dataItem.salutation"
            (selectionChange)="changeSalutation($event)"
          >
          </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        tabindex="2"
        field="firstName"
        title="Vorname"
      ></kendo-grid-column>
      <kendo-grid-column
        tabindex="3"
        field="lastName"
        title="Nachname"
      ></kendo-grid-column>
      <kendo-grid-column
        tabindex="4"
        field="email"
        title="Email (optional)"
      ></kendo-grid-column>
      <kendo-grid-column
        tabindex="5"
        field="additiveField"
        title="Zusatzfeld (optional)"
      ></kendo-grid-column>

      <kendo-grid-column
        *ngIf="
          checkPortalType([
            enumPortalDisplayTypes.Expanded,
            enumPortalDisplayTypes.full
          ])
        "
        tabindex="6"
        field="birthdate"
        editor="date"
        format="dd.MM.yyyy"
        title="Geburtsdatum"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <p>{{ dataItem.birthdate | date : "dd.MM.yyyy" }}</p>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        *ngIf="checkPortalType([enumPortalDisplayTypes.full])"
        tabindex="7"
        field="birthplace"
        title="Geburtsort"
      ></kendo-grid-column>

      <kendo-grid-column
        tabindex="8"
        field="differingCertificateCompanyName"
        title="abweichender Firmenname"
      >
      </kendo-grid-column>

      <kendo-grid-column>
        <ng-template kendoGridCellTemplate let-dataItem>
          <button
            kendoGridRemoveCommand
            kendoButton
            size="large"
            themeColor="error"
          >
            <kendo-icon name="delete"></kendo-icon>
            Entfernen
          </button>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>

<kendo-dialog
  title="Bitte bestätigen"
  *ngIf="isDeleteDialogOpened"
  (close)="isDeleteDialogOpened = false"
  [minWidth]="250"
  [width]="450"
>
  <p style="margin: 30px; text-align: center">
    Möchten Sie wirklich
    <b
      >{{ selectedParticipant?.firstName }}
      {{ selectedParticipant?.lastName }}</b
    >
    als Teilnehnmer aus diesem Seminar entfernen?
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="isDeleteDialogOpened = false">
      Abbrechen
    </button>
    <button kendoButton (click)="removeHandler()" primary="true">
      Ja, bestätigen
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog
  title="Importieren Sie Teilnehmer per Excel"
  *ngIf="isExcelImportDialogOpened"
  (close)="isExcelImportDialogOpened = false"
  [minWidth]="250"
  [width]="850"
  ><div class="scroll-able-dialog">
    <p class="leading-snug">
      Mit dieser Funktion können Sie Teilnehmer aus Ihrer eigenen Excel-Tabelle
      oder unserer Vorlage auf einfache Art einpflegen. Bitte beachten Sie auf
      das richtige Format Ihrer Excel. <br />
      Geben Sie Ihren Spalten die folgenden Überschriften, die Sortierung oder
      gegebenfalls zusätzliche Spalten werden nicht berücksichtigt.
    </p>
    <table class="border border-collapse w-full mt-4 mb-4 p-8">
      <tr>
        <td class="border p-2">Anrede</td>
        <td class="border p-2">Vorname</td>
        <td class="border p-2">Nachname</td>
        <td class="border p-2">Geburtsdatum</td>
        <td class="border p-2">Geburtsort</td>
        <td class="border p-2">Email</td>
        <td class="border p-2">Zusatzfeld</td>
        <td class="border p-2">Abweichende_Firma_Zertifikat</td>
      </tr>
    </table>
    <p>
      Vorname und Nachname sind Pflichtfelder. Die Restlichen sind optional.
    </p>
    <br />
    <p>Laden Sie unsere Mustertabelle herunter:</p>
    <a
      href="../../../../assets/files/ITCconnect Mustertabelle.xlsx"
      download
      class="inline-block bg-blue-500 px-3 py-2 text-white mt-2 mb-4"
      >Mustertabelle Herunterladen</a
    >

    <form class="k-form" [formGroup]="uploadFilesFormGroup">
      <kendo-formfield class="rounded shadow">
        <kendo-fileselect
          formControlName="files"
          [restrictions]="myRestrictions"
          [(ngModel)]="selectedFiles"
        ></kendo-fileselect>
      </kendo-formfield>
    </form>
    <kendo-dialog-actions>
      <button kendoButton (click)="isExcelImportDialogOpened = false">
        Abbrechen
      </button>
      <button
        kendoButton
        (click)="importParticipantsThroughExcel()"
        primary="true"
      >
        Importieren
      </button>
    </kendo-dialog-actions>
  </div>
</kendo-dialog>
