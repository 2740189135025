<div class="max-w-7xl mx-auto px-8 mt-24 mb-10">
  <div
    class="flex justify-between items-center"
    *ngIf="assignedItcContactPerson"
  >
    <div class="">
      <h1 class="text-4xl text-gray-800">
        Hallo {{ contactPerson?.firstName }} {{ contactPerson?.lastName }}
      </h1>
      <p class="text-gray-600 text-lg">
        {{ contactPerson?.email }}
      </p>
      <div class="mt-2 mb-4">
        <a class="bg-blue-100 px-2 py-2 text-blue-600 text-sm mb-6" routerLink="/dashboard/seminars">
          <kendo-icon name="arrow-left"></kendo-icon>
          Zurück zur Übersicht</a
        >
      </div>
    </div>

    <div class="border border-blue-500 p-3 bg-gray-50">
      <p class="text-md text-gray-700 font-semibold">
        Sie benötigen Hilfe oder haben Fragen?
        <br />
        Ihr Kontakt bei der ITC:
      </p>

      <div class="text-gray-600 mt-2">
        {{ assignedItcContactPerson?.firstName }}
        {{ assignedItcContactPerson?.lastName }}
        <br />
        <a href="mailto:{{ assignedItcContactPerson?.email }}">{{
          assignedItcContactPerson?.email
        }}</a>
        <br />
        <a href="tel:{{ assignedItcContactPerson?.phone }}">{{
          assignedItcContactPerson?.phone
        }}</a>
      </div>
    </div>
  </div>
</div>
