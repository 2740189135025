import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/_services/common.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.css']
})
export class LoadingScreenComponent implements OnInit {

  show: boolean = false;

  constructor(
    private commonService: CommonService
  ) { 
    this.commonService.getToggleLoadingScreen().subscribe(() => {
      this.show = !this.show;
    });
  }

  ngOnInit(): void {
  }
}
