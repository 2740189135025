import { Component, OnInit, Output, TemplateRef, ViewChild, EventEmitter } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { Subscription } from 'rxjs';
import { CustomNotificationService } from '../../../_services/custom-notification.service';
import { NotificationType } from '../../../_shared/enums/notificationType';

@Component({
  selector: 'app-notification',
  styleUrls: ['./notification.component.css'],
  templateUrl: './notification.component.html'

})

export class NotificationComponent implements OnInit {

  subscription: Subscription;
  public message: string;

  @ViewChild("template", { read: TemplateRef })
  public notificationTemplate: TemplateRef<any>;



  constructor(
    private notificationService: NotificationService,
    private customNotificationService: CustomNotificationService
  ) { }

  ngOnInit(): void {
    this.subscription = this.customNotificationService.getObservable().subscribe((notificationObject) => {
      this.show(notificationObject.notificationType, notificationObject.message);
    });
  }

  public show(notificationType?: NotificationType, message?: string): void {
    this.message = message;
    let notificationRef: NotificationRef = this.notificationService.show({
      content: this.message,
      animation: { type: "slide", duration: 200 },
      position: { horizontal: "right", vertical: "top" },
      type: { style: notificationType, icon: true },
      hideAfter: 10000
    });
    
  }

}
