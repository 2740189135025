import { Component, Input, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/_services/local-storage.service';

@Component({
  selector: 'app-seminar-info',
  templateUrl: './seminar-info.component.html',
  styleUrls: ['./seminar-info.component.css']
})
export class SeminarInfoComponent implements OnInit {

    contactPerson: any;
    @Input() assignedItcContactPerson: any;

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit(): void {
    this.contactPerson = this.localStorageService.getCurrentUser();
  }

}
