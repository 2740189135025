<div class="fixed top-0 w-full shadow-lg bg-blue-600" style="z-index: 9999">
  <div class="max-w-7xl mx-auto px-8 py-3">
    <div class="flex flex-wrap justify-between items-center">
      <div>
        <img
          class="h-7"
          src="../../../../assets/images/logo_white.svg"
          alt=""
        />
      </div>
      <div class="hidden sm:block">
        <span class="text-sm text-blue-800 bg-blue-300 py-1 px-16 rounded-sm">
          Angemeldet mit: {{ contactPerson?.email }}</span
        >
      </div>
      <div class="">
        <a
          class="
            text-white text-sm
            inline-flex
            items-center
            cursor-pointer
            hover:text-gray-400
          "
          (click)="logout()"
          >Abmelden
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 ml-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
