import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluate.component.html',
  styleUrls: ['./evaluate.component.css'],
})
export class EvaluateComponent implements OnInit {
  public valueHorizontal: number[] = [5, 5, 5];
  public min = 1;
  public max = 5;

  constructor() {}

  ngOnInit(): void {
    setInterval(() => {
      console.log(this.valueHorizontal);
    }, 2000);
  }
}
