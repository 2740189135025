import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { CommonService } from 'src/app/_services/common.service';
import { CustomNotificationService } from 'src/app/_services/custom-notification.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { NavigationService } from 'src/app/_services/navigation.service';
import { NavigationTypes } from 'src/app/_shared/enums/navigationTypes';
import { NotificationType } from 'src/app/_shared/enums/notificationType';

@Component({
  selector: 'app-seminars-overview',
  templateUrl: './seminars-overview.component.html',
  styleUrls: ['./seminars-overview.component.css']
})
export class SeminarsOverviewComponent implements OnInit {

  seminars: any[];

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private customNotificationService: CustomNotificationService,
    private navigationService: NavigationService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public async loadData() {
    this.commonService.sendToggleLoadingScreen();
    this.seminars = await this.apiService.getSeminarsByContactPerson(this.localStorageService.getCurrentUser().id).toPromise()
      .catch((error) => {
        this.customNotificationService.show(NotificationType.error, error.message);
      });
    this.commonService.sendToggleLoadingScreen();
  }

  public getSingleSeminar(seminarData: any, event) {
    this.navigationService.navigate(NavigationTypes.seminar, event.dataItem.seminarUuid);
  }

}
