import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css'],
})
export class InstructionComponent implements OnInit {
  public showVideo = true;

  instruction: any[] = [
    {
      section: 'Hilfe/Anmeldung',
      link: 'anmeldung',
      heading: 'ITC Connect Anmeldung',
      intro: ' So melden Sie sich im ITC Connect Portal an',
      steps: [
        {
          point: 'Schritt 1: Zugansdaten',
          content:
            'Ihre Zungangsdaten zum ITC Connect Portal erhalten Sie per E-Mail. Die Zugangsdaten für den Login im Portal bestehen aus einer E-Mail und einem Passwort. Geben Sie diese Daten niemals an unbefugte weiter. In der E-Mail mit den Zugangsdaten sehen Sie außerdem relevante Daten zu Ihrem Seminar wie Name, Datum und Ansprechpartner.',
          image: '../../../assets/1.png',
        },
        {
          point: 'Schritt 2: ITC Connect aufrufen',
          content:
            'Sie gelangen über den Teilnehmer eintragen Button direkt auf Ihre persönliche Login Seite des ITC Connect Portals. Sollte der Button aus technischen Gründen nicht funktionieren, so können Sie den Link in der E-Mail kopieren und manuell in Ihren Browser einfügen. Bitte achten Sie darauf, dass Sie sich nicht über https://connect.itc-graf.de direkt anmelden können. Sie müssen dem Link aus der E-Mail folgen.',
          image: '../../../assets/2.png',
        },
        {
          point: 'Schritt 3: Login',
          content:
            'Nachdem Sie dem Link aus der E-Mail gefolgt sind, sehen Sie einen Login-Screen. Geben Sie nun die Zugangsdaten aus der E-Mail ein und bestätigen Sie mit "Anmelden" oder durch drücken der Enter-Taste.',
          image: '../../../assets/3.png',
        },
      ],
    },
    {
      section: 'Hilfe/Seminarübersicht',
      link: 'uebersichtSeminare',
      heading: 'Seminarübersicht',
      intro: ' Ihre Seminare im Überblick',
      steps: [
        {
          point: 'Schritt 1: Seminarübersicht',
          content:
            'Nachdem erfolgreichen Login, sehen Sie eine Übersicht der Ihnen zugeordneten Seminare. Sie können ein Seminar anklicken, um es zu bearbeiten.',
          image: '../../../assets/4.png',
        },
      ],
    },
    {
      section: 'Hilfe/Teilnehmer eintragen',
      link: 'eintragen',
      heading: 'Teilnehmer eintragen',
      intro: 'Fügen Sie Teilnehmer zu Ihren Seminaren hinzu',
      steps: [
        {
          point: 'Schritt 1: Teilnehmer hinzufügen',
          content:
            'Um einen Teilnehmer hinzuzufügen, müssen Sie zunächst das Seminar öffnen, welches Sie bearbeiten wollen. Im unteren Teil sehen Sie eine Tabelle mit Teilnehmern, diese ist beim ersten Öffnen leer.',
          image: '../../../assets/5.png',
        },
        {
          point: 'Schritt 2: Teilnehmerinformationen hinzufügen',
          content: 'Tragen Sie nun die daten zu Ihrem Teilnehmer ein.',
          image: '../../../assets/6.png',
        },
        {
          point: 'Schritt 3: Teilnehmer speichern nicht möglich',
          content:
            'Sollte das Speichern nicht möglich sein, da der Speichern-Button sich nicht anklicken lässt, so müssen Sie alle Pflicht Informationen hinterlegen.',
          image: '../../../assets/7.png',
        },
        {
          point: 'Schritt 4: Teilnehmer speichern',
          content:
            'Sind alle Informationen eingetragen, so lässt sich der Teilnehmer abspeichern. Pflichtfelder: Vorname, Nachname.',
          image: '../../../assets/8.png',
        },
      ],
    },
    {
      section: 'Hilfe/Teilnehmer bearbeiten',
      link: 'teilnehmerBearbeiten',
      heading: 'Teilnehmer bearbeiten',
      intro: 'Ändern Sie einen Teilnehmer',
      steps: [
        {
          point: 'Schritt 1: Teilnehmer auswählen',
          content: 'Klicken Sie den zu bearbeitenden Teilnehmer an.',
          image: '../../../assets/9.png',
        },
        {
          point: 'Schritt 2: Daten ändern und speichern',
          content:
            'Passen Sie die Informationen des Teilnehmers an und speichern Sie.',
          image: '../../../assets/10.png',
        },
      ],
    },
    {
      section: 'Hilfe/Teilnehmer entfernen',
      link: 'teilnehmerEntfernen',
      heading: 'Teilnehmer entfernen',
      intro: 'Entfernen Sie einen Teilnehmer',
      steps: [
        {
          point: 'Schritt 1: Teilnehmer auswählen',
          content:
            'Klicken Sie beim zu entfernenden Teilnehmer auf den Entfernen-Button. Der Teilnehmer verschwindet aus der Liste.',
          image: '../../../assets/11.png',
        },
      ],
    },
  ];

  public gridData: any[] = [
    {
      anrede: 'Herr',
      vorname: 'Max',
      nachname: 'Mustermann',
      zusatzfeld: 'test',
      geburtsdatum: '06.07.1980',
    },
    {
      anrede: '',
      vorname: 'Petra',
      nachname: 'Musterfrau',
      zusatzfeld: 'IT-Abteilung',
      geburtsdatum: '11.12.1992',
    },
    {
      anrede: 'Frau',
      vorname: 'Tabea',
      nachname: 'Musterfrau',
      zusatzfeld: 'test',
      geburtsdatum: '02.09.1979',
    },
    {
      anrede: 'Herr',
      vorname: 'Max',
      nachname: 'Mustermann',
      zusatzfeld: 'test',
      geburtsdatum: '28.03.1964',
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
