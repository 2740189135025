import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  private loadingScreenSubject = new Subject<any>();

  sendToggleLoadingScreen() {
    this.loadingScreenSubject.next();
  }
  getToggleLoadingScreen() {
    return this.loadingScreenSubject.asObservable();
  }
}
