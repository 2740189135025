import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { CommonService } from 'src/app/_services/common.service';
import { LocalStorageService } from 'src/app/_services/local-storage.service';
import { AddressTypes } from 'src/app/_shared/enums/addressTypes';

@Component({
  selector: 'app-seminar',
  templateUrl: './seminar.component.html',
  styleUrls: ['./seminar.component.css']
})
export class SeminarComponent implements OnInit {

  seminarUuid: string;
 
  seminar: any;
  participantsData = [];
  addressSeminarOrt: any;

  portalDisplayType: number;

  contactPerson: any;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  public async loadData () {
    this.commonService.sendToggleLoadingScreen();
    this.seminarUuid = this.route.snapshot.paramMap.get('seminarUuid');
    this.seminar = await this.apiService.getSingleSeminar(this.seminarUuid).toPromise();
    console.log(this.seminar);
    this.portalDisplayType = this.seminar?.course?.courseType?.portalDisplayTypes?.id;
    for (let participant of this.seminar.participants) {
      this.participantsData.push(this.convertToGridParticipantsData(participant));
    }
    this.commonService.sendToggleLoadingScreen();
  }

  public convertToGridParticipantsData(participantsData: any) {
    return {
      id: participantsData.participantData.id,
      salutation: participantsData.participantData.salutation,
      firstName: participantsData.participantData.firstName,
      lastName: participantsData.participantData.lastName,
      email: participantsData.participantData.email,
      additiveField: participantsData.participantData.additiveField,
      birthplace: participantsData.participantData.birthplace,
      birthdate: (participantsData.participantData.birthdate) ? new Date(participantsData.participantData.birthdate) : null,
      differingCertificateCompanyName: participantsData.differingCertificateCompanyName
    }
  }

  // public convertToGridAddressData(addressData: any, addressType: number) {
  //   return {
  //     type: addressType,
  //     id: addressData.id,
  //     companyName: addressData.companyName,
  //     street: addressData.street,
  //     postcode: addressData.postcode,
  //     place: addressData.place,
  //     salutation: addressData.contactPerson.salutation,
  //     firstName: addressData.contactPerson.firstName,
  //     lastName: addressData.contactPerson.lastName,
  //     email: addressData.contactPerson.email,
  //     phone: addressData.contactPerson.phone,
  //   }
  // }

}
